import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home.vue'
export const Layout = () => import("@/layout/index.vue");
export const LayoutAI = () => import("@/layout/index_content.vue");

const routeList: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home.vue"),
        name: "home",
        meta: { title: "home", requireAuth: true },
      },
    ],
  },
  {
    path: "/about",
    component: Layout,
    children: [
      {
        path: "/about",
        component: () => import("@/views/about.vue"),
        name: "about",
        meta: { title: "about", requireAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue'),
    meta: {
      title: 'register'
    }
  },
  // {
  //   path: '/mobile/index',
  //   name: 'mobile',
  //   component: () => import('@/views/mobile/index.vue'),
  //   meta: {
  //     title: 'mobile'
  //   }
  // },
  {
    path: "/mobile",
    component: Layout,
    children: [
      {
        path: "/mobile/index",
        component: () => import("@/views/mobile/index.vue"),
        name: "mobileindex",
        meta: { title: "mobile", requireAuth: true },
      },
      {
        path: "/mobile/chat",
        component: () => import("@/views/mobile/chat.vue"),
        name: "mobilechat",
        meta: { title: "mobilechat", requireAuth: true },
      },
      {
        path: "/mobile/recorder",
        component: () => import("@/views/mobile/recorder.vue"),
        name: "mobilerecorder",
        meta: { title: "mobilerecorder", requireAuth: true },
      },
    ],
  }
  // {
  //   path: '/mobile/ai/chat',
  //   name: 'mobilechat',
  //   component: () => import('@/views/mobile/ai.vue'),
  //   meta: {
  //     title: 'mobilechat'
  //   }
  // }
]


// 静态路由
export const aiRoutes: RouteRecordRaw[] = [
  {
    path: "/ai",
    component: LayoutAI,
    redirect: "/ai/text",
    children: [
      {
        path: "/ai/chat",
        component: () => import("@/views/chat.vue"),
        name: "chat",
        meta: { title: "chat", requireAuth: true },
      },
      {
        path: "/ai/chatwhy",
        component: () => import("@/views/chatwhy.vue"),
        name: "chatwhy",
        meta: { title: "chatwhy", requireAuth: true },
      },
      {
        path: "/ai/chatlife",
        component: () => import("@/views/chatlife.vue"),
        name: "chatlife",
        meta: { title: "chatlife", requireAuth: true },
      },
      {
        path: "/ai/chattest",
        component: () => import("@/views/chattest.vue"),
        name: "chattest",
        meta: { title: "chattest", requireAuth: true },
      },
      {
        path: "/ai/chatenglish",
        component: () => import("@/views/chat_english.vue"),
        name: "chatenglish",
        meta: { title: "chatenglish", requireAuth: true },
      },
      {
        path: "/ai/text",
        component: () => import("@/views/text.vue"),
        name: "text",
        meta: { title: "text", requireAuth: true },
      },
      {
        path: "/ai/data",
        component: () => import("@/views/data.vue"),
        name: "data",
        meta: { title: "data", requireAuth: true },
      },
      {
        path: "/ai/trans",
        component: () => import("@/views/trans.vue"),
        name: "trans",
        meta: { title: "trans", requireAuth: true },
      },
    ],
  },
];

let routes = [...routeList, ...aiRoutes]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * 重置路由
 */
export function resetRouter() {
  router.replace({ path: "/login" });
}

export default router
